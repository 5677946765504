import { withPlugins, eventBus, domEvents, device } from '@spon/plugins';
import { Collapse } from 'bootstrap';
import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';

function Header({ node, name, plugins: { addEvents, device } }) {
	if (!node) return;
	/*
	 *		Variables
	 */

	const breakpoint = 1024;

	// Headroom config
	const options = {
		offset: node.clientHeight * 1.25 || 0,
		tolerance: {
			up: 5,
			down: 0,
		},
	};
	const headroom = new Headroom(node, options);

	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `${windowTop * -1}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';

			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);

			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	const nav = toggle({
		button: node.querySelector('.o-burger'),
		name,
		activeClass: 'is-active',
	});

	/*
	 *		Functions
	 */

	function clickWhenOpenHandle({ target }) {
		if (target.id === 'header' || target.closest('#header')) return; // Continue if clicking within #Header
		nav.close();
	}

	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('nav-open');
		target.classList.add('is-open');

		eventBus.emit('menu:open');

		lock.capture();
		closeModal();

		document.addEventListener('click', clickWhenOpenHandle);
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('nav-open');
		target.classList.remove('is-open');

		eventBus.emit('menu:close');

		lock.release();

		document.removeEventListener('click', clickWhenOpenHandle);
	});
	// Main Mobile Menu END

	function onKeyDown(event) {
		const { key } = event;
		if (key === 'Escape') {
			closeModal();
			if (nav.isOpen) {
				nav.close();
			}
		}
	}

	const headerBackground = node.querySelector('.header__dropdown');

	// Level 1
	const level1Nav = node.querySelector('.nav__list--level-1');
	const collapseElementList = level1Nav.querySelectorAll('.nav__list--level-2');
	const collapseList = [...collapseElementList].map(
		collapseEl =>
			new Collapse(collapseEl, {
				parent: '#primary-navigation',
				toggle: false,
			})
	);

	function onPrimaryClick(event) {
		if (window.innerWidth < breakpoint) return;
		// event.preventDefault();

		// if open; close.. vise versa
		if (event.target.classList.contains('collapsed')) {
			node.classList.remove('dropdown-open');
			eventBus.emit('menu:close');
			headerBackground.style.height = '';
		} else {
			node.classList.add('dropdown-open');
			eventBus.emit('menu:open');

			if (window.innerWidth >= breakpoint) {
				// animate BG height
				const acc = event.target.nextElementSibling ?? null;
				if (!acc) return;

				const { top } = acc.getBoundingClientRect();
				const height = acc.scrollHeight;

				headerBackground.style.height = `${headerBackground.offsetHeight}px`;
				headerBackground.style.height = `${top + height + 20}px`;
			}
		}
	}

	function closeModal() {
		node.classList.remove('dropdown-open');
		eventBus.emit('menu:close');
		if (window.innerWidth >= breakpoint) hideNavItems(); // keep on mobile; as we want accordion open if contains current-page
	}

	function hideNavItems() {
		collapseList.forEach(item => {
			item.hide();
		});
	}

	// Headroom
	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());
	eventBus.on('modal:has-opened', () => {
		headroom.unpin();
		headroom.freeze();
	});

	/*
	 *		Init
	 */
	nav.init();
	headroom.init();

	/*
	 *		Events
	 */

	addEvents({
		'click [data-bs-toggle="collapse"]': onPrimaryClick,
		'click [data-nav-close]': closeModal,
	});

	addEvents(document.body, {
		click: ({ target }) => {
			if (target.id === 'header' || target.closest('#header')) return;
			closeModal();
			node.classList.remove('dropdown-open');
		},
		keydown: onKeyDown,
	});

	device.at(`(min-width:${breakpoint}px)`, {
		on: () => {
			// log('desktop; do nothing');
			// navAccordions.forEach(item => {
			// 	item.dispose();
			// });
		},
		off: () => {
			// log('mobile; activate relevant active item(s)');
			node.querySelector('.nav__subwrapper--show');
			// const activeNavAccordion = navAccordions.filter(
			// 	a => a._element === activeNavItem
			// )[0];
			// if (activeNavAccordion) {
			// 	activeNavAccordion.show();
			// }
		},
	});

	return () => {};
}

export default withPlugins(domEvents, device)(Header);
