function Loader({ node }) {
	if (!node) return;

	const startTime = new Date(); // Get the current time

	const minDuration = 1000; // Set the minimum duration of the loading screen
	let elapsedMilliseconds = 0; // Initialize elapsed time

	const checkReady = () => {
		if (
			elapsedMilliseconds > minDuration &&
			document.readyState === 'complete'
		) {
			// good to go!
			setTimeout(() => {
				node.style.opacity = 0;
			}, 100);
			clearInterval(interval);
		}
	};

	const interval = setInterval(() => {
		const currentTime = new Date(); // Get the current time
		elapsedMilliseconds = currentTime - startTime;

		checkReady();
	}, 100);
}

export default Loader;
