function FadeIn({ node }) {
	/*
	 *		Variables
	 */

	// Main block animate in
	const observer = new IntersectionObserver(onIntersection, {
		rootMargin: '0px 0px -25% 0px',
		threshold: 0,
	});

	const elements = [
		...node.querySelectorAll('[data-fade-in]'),
		...node.querySelectorAll('[data-rose]'),
	];
	elements.forEach(element => observer.observe(element));

	// Rose animate in
	// const roseObserver = new IntersectionObserver(onIntersection, {
	// 	rootMargin: '0px 0px -50% 0px',
	// 	threshold: 0,
	// });
	// const roseElements = [...node.querySelectorAll('[data-rose]')];
	// roseElements.forEach(element => roseObserver.observe(element));

	function onIntersection(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				const { target } = entry;

				if (!target.classList.contains('has-moved')) {
					target.classList.add('has-moved'); // handled with CSS.
				}
			}
		});
	}
}
export default FadeIn;
