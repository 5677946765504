import { withPlugins, domEvents } from '@spon/plugins';

function ScrollTop({ node, plugins: { addEvents } }) {
	function onClick(event) {
		event.preventDefault();

		const {
			target: {
				dataset: { scroll },
			},
		} = event;

		const item = scroll ? document.querySelector(scroll) : node; // else document.body
		const offset = item === node ? 0 : 75;

		if (item) {
			window.scroll({
				top: item.getBoundingClientRect().top + scrollY - offset,
				left: 0,
				behavior: 'smooth',
			});
		}
	}

	addEvents({
		'click [data-scroll]': onClick,
	});
}

export default withPlugins(domEvents)(ScrollTop);
