import '/src/scss/style.scss';

if (import.meta.hot) {
	import.meta.hot.accept();
}

import { loadApp, loadModule } from '@spon/core';
import 'lazysizes';

import Header from '@/globals/Header';
import SetVh from '@/globals/SetVh';
import FadeIn from '@/globals/FadeIn';
import Loader from '@/globals/Loader';
import ScrollTop from '@/globals/ScrollTop';
import '@/plugins/logger';
import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';
import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';

webfontloader();

// load from data-behaviours
const app = loadApp(name => import(`./behaviours/${name}.js`), document.body); // eslint-disable-line

loadModule({
	module: Header,
	id: 'header',
	node: document.querySelector('#header'),
	keepAlive: true,
});

loadModule({
	module: SetVh,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: FadeIn,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: Loader,
	id: 'set-vh',
	node: document.querySelector('.js-loader'),
	keepAlive: true,
});

loadModule({
	module: ScrollTop,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});

detectTab();
activeLinks(window.location.pathname);
localLinks();

// Fade during loading. Dont need to remove as dom is updated
// https://htmx.org/events
if (typeof htmx !== 'undefined') {
	// eslint-disable-next-line
	htmx.on('htmx:beforeSend', ({ target }) => {
		const results = document.getElementById('results');
		if (!results) return;
		results.classList.add('is-loading');
	});

	// Scroll to top of `sprig` container when interacting; but only when `scroll` is in Param (eg not filtering; but probably pagination)
	// eslint-disable-next-line
	htmx.on('htmx:afterSwap', ({ target }) => {
		localLinks(target);

		const shouldScroll = target.querySelector('.c-pagination');

		if (target && shouldScroll) {
			window.scrollTo({
				top: target.getBoundingClientRect().top + window.pageYOffset - 100,
				behavior: 'smooth',
			});
		}
	});
}
